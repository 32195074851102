import axios from 'axios';
import { setNetworkError } from '../actions/siteActions';
import * as actions from '../constants/action-types';
import {
    API_URL,
    GET_CANDIDATES_URL,
    GET_CANDIDATE_REFERENCES_URL,
    POST_CANCEL_REFEREE_REMINDER_URL,
    POST_CANCEL_REQUEST_REMINDER_URL,
    POST_CANDIDATE_COMMENT_URL,
    POST_DELETE_CANDIDATE_URL,
    POST_DELETE_REFERENCE_URL,
    POST_EDIT_NUM_OF_REFERENCES_URL,
    POST_SEND_CANDIDATE_REMINDER_URL,
    POST_SEND_REFEREE_REMINDER_URL,
} from '../constants/api.js';
import { updateLoggedInStatus } from './loginActions.js';

import { notificationMessage } from './notificationMessageActions';

export const getCandidate = (candidateId) => (dispatch) => {
    const url = API_URL + GET_CANDIDATES_URL;
    const config = {
        params: {
            pg: 1,
            id: candidateId,
        },
        withCredentials: true,
    };

    dispatch({
        type: actions.GET_CANDIDATE,
        payload: {
            candidateId,
        },
    });

    axios
        .get(url, config)
        .then(({ data }) => {
            if (data.status === 'success') {
                dispatch(updateLoggedInStatus(data));
                dispatch(receivedCandidate(data.data[0]));
                dispatch(getCandidateReferences(candidateId));
            }
        })
        .catch((error) => {
            dispatch(setNetworkError(error));
        });
};

const receivedCandidate = (candidate) => ({
    type: actions.RECEIVED_CANDIDATE,
    payload: {
        candidate,
    },
});

export const getCandidates =
    (start = 0, order, filters = {}, isFiltered = false, searchQuery = '') =>
    (dispatch) => {
        const url = API_URL + GET_CANDIDATES_URL;
        const config = {
            params: {
                start,
                ...(order && { order }),
                ...filters,
            },
            withCredentials: true,
        };
        const params = new URLSearchParams();

        params.append('q', searchQuery);

        dispatch({
            type: actions.GET_CANDIDATES,
            payload: {
                start,
                isFiltered,
            },
        });

        axios
            .post(url, params, config)
            .then(({ data }) => {
                dispatch(updateLoggedInStatus(data));
                if (data.status === 'success') {
                    dispatch(receivedCandidates(data.data, start, order));
                }
            })
            .catch((error) => {
                dispatch(setNetworkError(error));
            });
    };

const receivedCandidates = (candidates, start, sortOrder) => (dispatch) => {
    const currentActiveId = (candidates[0] && candidates[0].rid) || 0;

    dispatch({
        type: actions.RECEIVED_CANDIDATES,
        payload: {
            activeCandidateId: currentActiveId,
            candidates,
            start,
            sortOrder,
        },
    });

    if (currentActiveId) {
        dispatch(getCandidateReferences(currentActiveId));
    }
};

export const getCandidateReferences = (candidateId) => (dispatch) => {
    const url = API_URL + GET_CANDIDATE_REFERENCES_URL + candidateId;
    const config = { withCredentials: true };

    dispatch({
        type: actions.GET_CANDIDATE_REFERENCES,
        payload: {
            candidateId,
        },
    });

    axios
        .get(url, config)
        .then(({ data }) => {
            dispatch(updateLoggedInStatus(data));
            dispatch(receivedCandidateReferences(candidateId, data.data));
        })
        .catch((error) => {
            dispatch(setNetworkError(error));
        });
};

const receivedCandidateReferences = (candidateId, references) => {
    const updatedReferences = references.map((reference) => {
        const fraudDetected =
            !reference.is_gap &&
            (reference.fraudDetection.ipMatchesCandidate ||
                reference.fraudDetection.ipMatchesReferee ||
                reference.fraudDetection.browseridMatchesCandidate ||
                reference.fraudDetection.onKnownBadActorList ||
                reference.fraudDetection.domainAgeFailed);

        return {
            ...reference,
            fraudDetected,
        };
    });

    return {
        type: actions.RECEIVED_CANDIDATE_REFERENCES,
        payload: {
            candidateId,
            references: updatedReferences,
        },
    };
};

export const setActiveReference = (activeReferenceId) => ({
    type: actions.SET_ACTIVE_REFERENCE,
    payload: {
        activeReferenceId,
    },
});

const getNextCandidate = (candidates, id) => {
    if (Object.keys(candidates).length <= 1) return null;

    const keys = Object.keys(candidates);
    const location = keys.indexOf(String(id));

    if (location > -1) {
        const prevValue = candidates[keys[location - 1]];
        const nextValue = candidates[keys[location + 1]];

        return prevValue.rid || nextValue.rid;
    }

    return null;
};

export const deleteCandidate = (id) => (dispatch, getState) => {
    const requestUrl = `${API_URL}${POST_DELETE_CANDIDATE_URL}/${id}`;
    const params = new URLSearchParams();
    const config = { withCredentials: true };

    dispatch({ type: actions.DELETE_CANDIDATE, payload: { candidateId: id } });

    //TODO: Fix deleting candidates when theres nothing else in list for them to fallback on

    axios
        .post(requestUrl, params, config)
        .then(({ data }) => {
            if (data.status === 'success') {
                const nextCandidateId = getNextCandidate(getState().candidates.byId, id);

                if (nextCandidateId) dispatch(getCandidate(nextCandidateId));

                dispatch({
                    type: actions.CANDIDATE_DELETED,
                    payload: {
                        nextCandidateId,
                        candidateId: id,
                    },
                });
                dispatch(
                    notificationMessage({
                        type: 'success',
                        textKey: 'Requests.sidebar.deletedCandidateMsg',
                    })
                );
            } else {
                dispatch(deleteCandidateFailed(id));
            }
        })
        .catch((error) => {
            dispatch(deleteCandidateFailed(id));
            console.error(error);
        });
};

const deleteCandidateFailed = (candidateId) => (dispatch) => {
    dispatch(
        notificationMessage({
            type: 'error',
            textKey: 'Requests.sidebar.deleteCandidateFailedMsg',
        })
    );
    dispatch({
        type: actions.CANDIDATE_DELETE_FAILED,
        payload: {
            candidateId,
        },
    });
};

export const cancelRequestReminders = (rid) =>
    cancelReminders(POST_CANCEL_REQUEST_REMINDER_URL, rid);

export const cancelRefereeReminders = (rfid) =>
    cancelReminders(POST_CANCEL_REFEREE_REMINDER_URL, rfid);

const cancelReminders = (url, id) => (dispatch, getState) => {
    const requestUrl = `${API_URL}${url}/${id}`;
    const params = new URLSearchParams();
    const config = { withCredentials: true };
    const { activeCandidateId } = getState().candidates;

    dispatch(setCancelReminderLoader(activeCandidateId, true));

    axios
        .post(requestUrl, params, config)
        .then(({ data }) => {
            if (data.status === 'success') {
                dispatch(
                    notificationMessage({
                        type: 'success',
                        textKey: 'Requests.timeline.cancelRemindersSuccessMsg',
                    })
                );
                dispatch(getCandidate(activeCandidateId));
            } else {
                dispatch(cancelRemindersFailed());
            }
        })
        .catch((error) => {
            dispatch(cancelRemindersFailed());
            console.error(error);
        })
        .finally(() => dispatch(setCancelReminderLoader(activeCandidateId)));
};

const setCancelReminderLoader = (candidateId, isCancelling = false) => ({
    type: actions.SET_CANCEL_REMINDER_LOADER,
    payload: {
        candidateId,
        isCancelling,
    },
});

const cancelRemindersFailed = notificationMessage({
    type: 'error',
    textKey: 'Requests.timeline.cancelRemindersErrorMsg',
});

export const sendCandidateReminder = (rid) => sendReminder(POST_SEND_CANDIDATE_REMINDER_URL, rid);

export const sendRefereeReminder = (rfid) => sendReminder(POST_SEND_REFEREE_REMINDER_URL, rfid);

export const startResumeRefereeReminders = (rfid) =>
    startResumeReminders(POST_SEND_REFEREE_REMINDER_URL, rfid);

const sendReminder = (url, id) => (dispatch, getState) => {
    const requestUrl = `${API_URL}${url}/${id}`;
    const params = new URLSearchParams();
    const config = { withCredentials: true };
    const { activeCandidateId } = getState().candidates;

    dispatch(setSendReminderLoader(activeCandidateId, true));

    axios
        .post(requestUrl, params, config)
        .then(({ data }) => {
            if (data.status === 'success') {
                dispatch(
                    notificationMessage({
                        type: 'success',
                        textKey: 'Requests.timeline.sendReminderSuccessMsg',
                    })
                );
                dispatch(getCandidate(activeCandidateId));
            } else {
                dispatch(sendReminderFailed());
            }
        })
        .catch((error) => {
            dispatch(sendReminderFailed());
            console.error(error);
        })
        .finally(() => dispatch(setSendReminderLoader(activeCandidateId)));
};

const setSendReminderLoader = (candidateId, isSendingReminder = false) => ({
    type: actions.SET_SEND_REMINDER_LOADER,
    payload: {
        candidateId,
        isSendingReminder,
    },
});

const sendReminderFailed = notificationMessage({
    type: 'error',
    textKey: 'Requests.timeline.sendReminderErrorMsg',
});

const startResumeReminders = (url, id) => (dispatch, getState) => {
    const requestUrl = `${API_URL}${url}/${id}`;
    const params = new URLSearchParams();
    const config = { withCredentials: true };
    const { activeCandidateId } = getState().candidates;

    dispatch(setSendReminderLoader(activeCandidateId, true));

    axios
        .post(requestUrl, params, config)
        .then(({ data }) => {
            if (data.status === 'success') {
                dispatch(
                    notificationMessage({
                        type: 'success',
                        textKey: 'Requests.timeline.startResumeReminderSuccessMsg',
                    })
                );
                dispatch(getCandidate(activeCandidateId));
            } else {
                dispatch(startResumeRemindersFailed());
            }
        })
        .catch((error) => {
            dispatch(startResumeRemindersFailed());
            console.error(error);
        })
        .finally(() => dispatch(setSendReminderLoader(activeCandidateId)));
};

const startResumeRemindersFailed = notificationMessage({
    type: 'error',
    textKey: 'Requests.timeline.startResumeReminderErrorMsg',
});

export const deleteReference = (rfid) => (dispatch, getState) => {
    const requestUrl = `${API_URL}${POST_DELETE_REFERENCE_URL}/${rfid}`;
    const params = new URLSearchParams();
    const config = { withCredentials: true };
    const { activeCandidateId } = getState().candidates;

    dispatch({ type: actions.DELETE_REFERENCE });

    axios
        .post(requestUrl, params, config)
        .then(({ data }) => {
            if (data.status === 'success') {
                dispatch(
                    notificationMessage({
                        type: 'success',
                        textKey: 'Requests.timeline.deleteReferenceSuccessMsg',
                    })
                );
                dispatch({ type: actions.DELETE_REFERENCE_SUCCESS });
                dispatch(getCandidateReferences(activeCandidateId));
                dispatch(getCandidate(activeCandidateId));
            } else {
                dispatch(deleteReferenceFailed());
            }
        })
        .catch((error) => {
            dispatch(deleteReferenceFailed());
            console.error(error);
        });
};

const deleteReferenceFailed = () => (dispatch) => {
    dispatch(
        notificationMessage({
            type: 'error',
            textKey: 'Requests.timeline.deleteReferenceErrorMsg',
        })
    );

    dispatch({ type: actions.DELETE_REFERENCE_FAILED });
};

export const editNumberOfReferences = (numOfRefs) => (dispatch, getState) => {
    const { activeCandidateId, byId } = getState().candidates;
    const requestUrl = `${API_URL}${POST_EDIT_NUM_OF_REFERENCES_URL}/${activeCandidateId}`;
    const params = new URLSearchParams();
    const config = { withCredentials: true };
    const previousReferencesRequested = byId[activeCandidateId].refs_requested;

    if (previousReferencesRequested === numOfRefs) {
        return dispatch(
            notificationMessage({
                type: 'info',
                textKey: 'Requests.timeline.editNumOfReferencesInfoMsg',
            })
        );
    }

    params.append('inputrefnum', numOfRefs);

    dispatch({ type: actions.EDIT_NUM_OF_REFERENCES });

    axios
        .post(requestUrl, params, config)
        .then(({ data }) => {
            if (data.status === 'success') {
                dispatch(
                    notificationMessage({
                        type: 'success',
                        textKey: 'Requests.timeline.editNumOfReferencesSuccessMsg',
                        textValues: {
                            previousNumber: previousReferencesRequested,
                            newNumber: numOfRefs,
                        },
                    })
                );
                dispatch({ type: actions.EDIT_NUM_OF_REFERENCES_SUCCESS });
                dispatch(getCandidate(activeCandidateId));
            } else {
                dispatch(editNumberOfReferencesFailed());
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(editNumberOfReferencesFailed());
            console.error(error);
        });
};

const editNumberOfReferencesFailed = () => (dispatch) => {
    dispatch(
        notificationMessage({
            type: 'error',
            textKey: 'Requests.timeline.editNumOfReferencesErrorMsg',
        })
    );

    dispatch({ type: actions.EDIT_NUM_OF_REFERENCES_FAILED });
};

export const postCandidateComment = (rid, commentText) => (dispatch) => {
    const requestUrl = `${API_URL}${POST_CANDIDATE_COMMENT_URL}`;
    const params = new URLSearchParams();
    const config = { withCredentials: true };

    params.append('rid', rid);
    params.append('comment_text', commentText);

    dispatch({ type: actions.POST_CANDIDATE_COMMENT });

    axios
        .post(requestUrl, params, config)
        .then(({ data }) => {
            if (data.status === 'success') {
                dispatch({ type: actions.POST_CANDIDATE_COMMENT_SUCCESS });
                dispatch(getCandidate(rid));
                dispatch(
                    notificationMessage({
                        type: 'success',
                        textKey: 'Requests.sidebar.postCommentSuccessMsg',
                    })
                );
            } else {
                dispatch(postCandidateCommentFailed());
            }
        })
        .catch((error) => {
            dispatch(postCandidateCommentFailed());
            console.error(error);
        });
};

const postCandidateCommentFailed = () => (dispatch) => {
    dispatch(
        notificationMessage({
            type: 'error',
            textKey: 'Requests.sidebar.postCommentErrorMsg',
        })
    );
    dispatch({ type: actions.POST_CANDIDATE_COMMENT_FAILED });
};
